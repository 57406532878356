import React, { useState } from "react";
import "./styles/about.css";
import {
  faArrowLeft,
  faEnvelope,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { trackAction } from "../components/analytics";

const About = ({ handleBackClick }) => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="about-overlay">
      <div className="header" onClick={handleBackClick}>
        <img
          className="about-logo"
          src="/assets/images/logo/white_logo_with_text.png"
          alt="AscendAI Logo"
          onClick={() => {
            trackAction("Navigate", "Clicked", "About Logo");
            navigate("/");
          }}
        />
      </div>
      <div className="about-container" onClick={handleBackClick}>
        <div className="about-content">
          <h1>About Us</h1>
          <p>
            Our company is at the forefront of reshaping how businesses leverage
            AI technology, recognizing the critical importance of personalized
            communication solutions. We offer an innovative platform that allows
            companies to seamlessly integrate AI into their daily operations. By
            enabling businesses to upload their product data and historical
            service records, we facilitate the creation of customized AI
            assistants that are tailored to the specific demands of each
            business. These personalized assistants enhance customer
            interactions and streamline internal processes, providing a bespoke
            experience that meets the unique needs of every business.
          </p>
          <p>
            Our platform simplifies the adoption of sophisticated AI chat
            solutions, obviating the need for companies to invest heavily in
            specialized AI and data engineering teams. We are dedicated to
            democratizing access to AI technologies, making them manageable and
            accessible for businesses of all sizes. Through our user-friendly
            interface, companies can quickly develop interactive AI assistants
            that not only respond to user-specific data but also adhere to
            tailor-made AI policies. This customization ensures precise control
            over information access and functionality, empowering businesses to
            stay ahead in the competitive market by adopting cutting-edge AI
            solutions.
          </p>
          <button
            rel="noopener noreferrer"
            className="desktop-buttons"
            onClick={() => {
              trackAction("Email", "Clicked", "Clicked on Get in Touch Button");
              window.location.href = "mailto:contact@get-ascend.ai";
            }}
          >
            <FontAwesomeIcon className="fa-icon" icon={faEnvelope} />
            Get in Touch
          </button>
          <button
            onClick={handleBackClick}
            className="back-button desktop-buttons"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="fa-icon" icon={faArrowLeft} />
            Back
          </button>
          <button
            rel="noopener noreferrer"
            className="mobile-buttons"
            onClick={() => {
              trackAction(
                "Video",
                "Clicked",
                "Clicked on Watch Video Button on Mobile"
              );
              setShowVideo(true);
            }}
          >
            <FontAwesomeIcon className="fa-icon" icon={faVideo} />
            Watch the Video
          </button>
        </div>
        <div className="mobile-footer">
          <button
            onClick={() => {
              trackAction("Socials", "Clicked", "Linkedin Button Clicked");
              window.location.href =
                "https://www.linkedin.com/company/get-ascend-ai/";
            }}
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="fa-icon" icon={faLinkedin} />
          </button>
          <button
            onClick={() => {
              trackAction("Email", "Clicked", "Clicked on Email Button");
              window.location.href = "mailto:contact@get-ascend.ai";
            }}
          >
            <FontAwesomeIcon className="fa-icon" icon={faEnvelope} />
          </button>
        </div>
        {showVideo && (
          <div className="video-container">
            <video width="600" controls>
              <source
                src="/assets/videos/background_vid.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
