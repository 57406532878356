import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import About from "./about";
import {
  faVolumeMute,
  faVolumeUp,
  faPlay,
  faPause,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { trackAction } from "../components/analytics";

import "./styles/homepage.css";

const useMouseActivity = ({ timeout = 2000 }) => {
  const [isActive, setIsActive] = useState(true);
  const timeoutId = useRef(null);

  useEffect(() => {
    if (typeof window === "undefined" || window.innerWidth <= 1024) return; // Only run on desktops

    const handleActivity = () => {
      setIsActive(true);
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => setIsActive(false), timeout);
    };

    window.addEventListener("mousemove", handleActivity);

    handleActivity(); // Initialize as active

    return () => {
      clearTimeout(timeoutId.current);
      window.removeEventListener("mousemove", handleActivity);
    };
  }, [timeout]);

  return isActive;
};

const Homepage = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isActivityOverlayVisible, setIsActivityOverlayVisible] =
    useState(true);
  const [isAboutVisible, setIsAboutVisible] = useState(false);

  const isActive = useMouseActivity({ timeout: 2000 });

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsAboutVisible(true);
      } else {
        setIsAboutVisible(false);
      }
    };

    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (!isAboutVisible) {
      setIsActivityOverlayVisible(isActive || isMuted || !isPlaying);
    }
  }, [isActive, isMuted, isPlaying, isAboutVisible]);

  const handleAboutUsClick = () => {
    setIsAboutVisible(true);
    setIsActivityOverlayVisible(false);
    trackAction("About", "Viewed", "Clicked on About Us button");
  };

  const handleBackClick = () => {
    setIsAboutVisible(false);
    setIsActivityOverlayVisible(isActive || isMuted || !isPlaying);
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        trackAction("Video", "Paused", "Video control button toggle paused");
      } else {
        videoRef.current.play();
        trackAction("Video", "Played", "Video control button toggle played");
      }
      setIsPlaying(!isPlaying);
    }
  };
  const restartVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      setIsMuted(true);
      videoRef.current.play();
      setIsPlaying(true);
      trackAction("Video", "Restarted", "Video control button restart video");
    }
  };

  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
      trackAction("Video", "Sound", "Video control button toggle sound");
    }
  };

  const handleVideoEnded = () => {
    setIsMuted(true);
    videoRef.current.play();
    trackAction("Video", "Looped", "Video looped");
  };

  return (
    <div>
      <Helmet>
        <title>AscendAI</title>
      </Helmet>
      <div
        className={`homepage-overlay ${
          !isActivityOverlayVisible ? "hide-overlay" : ""
        }`}
      >
        <div className="header">
          <img
            className="homepage-logo"
            src="/assets/images/logo/white_logo_with_text.png"
            alt="AscendAI Logo"
            onClick={() => {
              trackAction("Navigate", "Clicked", "Homepage Logo");
              navigate("/");
            }}
          />
        </div>
        <div className="footer">
          <div className="overlay-text">
            <div className="video-control">
              <button onClick={togglePlayPause} style={{ fontSize: "1.3rem" }}>
                {isPlaying ? (
                  <FontAwesomeIcon icon={faPause} />
                ) : (
                  <FontAwesomeIcon icon={faPlay} />
                )}
              </button>
              <button onClick={restartVideo}>
                <FontAwesomeIcon icon={faArrowRotateRight} />
              </button>
              <button onClick={toggleSound}>
                {isMuted ? (
                  <FontAwesomeIcon icon={faVolumeUp} />
                ) : (
                  <FontAwesomeIcon icon={faVolumeMute} />
                )}
              </button>
            </div>
            Accelerate your path to success with customized AI solutions.
          </div>
          <div className="overlay-links">
            <button onClick={handleAboutUsClick} rel="noopener noreferrer">
              About Us
            </button>
            <button
              onClick={() => {
                trackAction("Socials", "Clicked", "Linkedin Button Clicked");
                window.location.href =
                  "https://www.linkedin.com/company/get-ascend-ai/";
              }}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </button>
          </div>
        </div>
      </div>

      {isAboutVisible && <About handleBackClick={handleBackClick} />}
      <video
        className="video-background"
        ref={videoRef}
        autoPlay
        muted={isMuted}
        onEnded={handleVideoEnded}
      >
        <source src="/assets/videos/background_vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Homepage;
