import AppRoutes from "./routes.jsx";
import "./App.css";

import { TRACKING_ID } from "./data/tracking";
import ReactGA from "react-ga4";

// Initialize Google Analytics
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

function App() {
  return (
    <div className="App">
      {/* <Navbar /> */}
      <AppRoutes />
    </div>
  );
}

export default App;
